<mat-form-field
  [matMenuTriggerFor]="timepickerMenu"
  #trigger="matMenuTrigger"
  [class.disabled]="disabled"
  class="input-timepicker"
>
  <input
    matInput
    readonly
    [required]="required"
    #inputRef="ngModel"
    [ngModel]="timeInputFormat"
    type="text"
    [placeholder]="placeholder"
    [disabled]="disabled"
  />
  <button mat-icon-button matSuffix [disabled]="disabled">
    <mat-icon class="icon-20px text-[#31333899]">access_time</mat-icon>
  </button>
  <mat-error *ngIf="inputRef.invalid && (inputRef.dirty || inputRef.touched)">This field is required</mat-error>
</mat-form-field>
<mat-menu #timepickerMenu="matMenu" (closed)="onClosedMenu()" class="popup-time-setting">
  <ng-template matMenuContent>
    <ngx-mat-timepicker
      [(ngModel)]="time"
      [showSpinners]="showSpinners"
      [stepHour]="stepHour"
      [stepMinute]="stepMinute"
      [stepSecond]="stepSecond"
      [showSeconds]="showSeconds"
      (click)="$event.stopPropagation()"
      (keyup.enter)="onSaveTime()"
    >
    </ngx-mat-timepicker>
    <div class="actions">
      <button mat-button>Cancel</button>
      <button mat-button color="primary" (click)="onSaveTime()">OK</button>
    </div>
  </ng-template>
</mat-menu>
