import { Injectable } from '@angular/core';
import { Order, QueryEntity } from '@datorama/akita';
import { PinMessageState, PinMessageStore } from './pin-message.store';

@Injectable({ providedIn: 'root' })
export class PinMessageQuery extends QueryEntity<PinMessageState> {
  constructor(protected override store: PinMessageStore) {
    super(store);
  }

  getEntityPin(messageId: string) {
    return this.getEntity(messageId);
  }

  countPinByChannel(channelId: string) {
    return this.selectCount(entity => entity.convoId === channelId);
  }

  getCountPinByChannel(channelId: string) {
    return this.getCount(entity => entity.convoId === channelId);
  }

  selectPinByChannel(channelId: string) {
    return this.selectAll({
      filterBy: entity => entity.convoId === channelId
    });
  }

  selectPinByChannelSort(channelId: string) {
    return this.selectAll({
      filterBy: entity => entity.convoId === channelId,
      sortBy: 'at',
      sortByOrder: Order.DESC
    });
  }
}
