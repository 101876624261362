import { Injectable } from '@angular/core';
import { Query } from '@datorama/akita';
import { ORG_CONNECT_STATUS } from 'libs/portal/org/feature/org-link/src/lib/shared/constants';
import { map } from 'rxjs';
import { CaseMetaData } from './cases-metadata.model';
import { CaseMetaDataStore } from './cases-metadata.store';

@Injectable({ providedIn: 'root' })
export class SCMetaDataQuery extends Query<CaseMetaData> {
  scMetaData$ = this.select();

  productList$ = this.select('productList');
  typeList$ = this.select('caseTypeList');
  severityList$ = this.select('caseSeverityList');
  suppliers$ = this.select('suppliers');
  connectedOrg$ = this.select('connectedOrg');

  constructor(store: CaseMetaDataStore) {
    super(store);
  }

  getProductById(productId: number) {
    return this.getValue().productList?.find(x => x.id === productId);
  }

  selectActiveOrgConnect() {
    return this.connectedOrg$.pipe(map(list => list?.filter(l => l.status === ORG_CONNECT_STATUS.active)));
  }

  getActiveAnotherOrgConnect() {
    return this.getValue()
      .connectedOrg.filter(list => list.status === ORG_CONNECT_STATUS.active)
      .map(list => list.anotherOrg);
  }
}
