import { Injectable } from '@angular/core';
import { EntityUIQuery, ID, Order, QueryEntity } from '@datorama/akita';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { TxnUI } from './txn-ui.model';
import { Txn } from './txn.model';
import { TxnState, TxnStore, TxnUIState } from './txn.store';

@Injectable({ providedIn: 'root' })
export class TxnQuery extends QueryEntity<TxnState> {
  override ui: EntityUIQuery<TxnUIState>;

  hasMorePending$ = this.select('statePending').pipe(map(x => x?.hasMore));
  fetchingTxn$ = this.select('fetchingTxn');

  constructor(protected override store: TxnStore) {
    super(store);
    this.createUIQuery();
  }

  getTxn(txnUuid: string) {
    return this.getEntity(txnUuid);
  }

  selectEntity$(txnUuid: string) {
    return this.selectEntity(txnUuid);
  }

  getActiveTxn() {
    return this.getActive();
  }

  activeId$() {
    return this.selectActiveId();
  }

  activeTxn$() {
    return this.selectActive();
  }

  selectJourneyTxnByCustomer(customerUuid: string, limit = 5) {
    return this.selectAll({
      filterBy: entity => entity.customerUuid === customerUuid,
      limitTo: limit,
      sortBy: 'createdAt',
      sortByOrder: Order.DESC
    });
  }

  findTxnByConvo(convoId: string) {
    return this.getAll({
      filterBy: entity =>
        entity.publicConvoId === convoId || entity.teamConvoId === convoId || entity.txnUuid === convoId,
      limitTo: 1
    })?.[0];
  }

  getTeamConvoTxn(convoId: string) {
    return this.getAll({
      filterBy: entity => entity?.teamConvoId === convoId,
      limitTo: 1
    })?.[0];
  }

  selectPropertyTxn<K extends keyof Txn>(id: string, property: K) {
    return this.selectEntity(id, property);
  }

  selectPropertyFuncTxn<R>(id: string, project: (entity?: Txn) => R): Observable<R> {
    return this.selectEntity(id, project);
  }

  selectUIState<K extends keyof TxnUI>(id: string | ID, property: K) {
    return this.ui.selectEntity(id, property);
  }

  getUiState(txnUuid: string | ID) {
    return this.ui.getEntity(txnUuid);
  }

  hasMorePending() {
    return this.select(entity => entity?.statePendingV2?.hasMore);
  }

  hasMoreActive() {
    return this.select(entity => entity?.stateActiveV2?.hasMore);
  }

  selectTxnsAssignToMeV2(meIdentity: string) {
    return this.selectAll({
      filterBy: entity =>
        !entity.isClosedV2 && entity?.lastAssignedAgents?.findIndex(identityUuid => identityUuid === meIdentity) > -1,
      sortBy: 'createdAt',
      sortByOrder: Order.DESC
    });
  }

  selectTxnsCreatedToMe(meIdentity: string) {
    return this.selectAll({
      filterBy: entity => entity.caseInfo?.createdByIdentity === meIdentity,
      sortBy: 'customerName',
      sortByOrder: Order.DESC
    });
  }
}
