import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ExtensionBase } from '@b3networks/api/bizphone';
import { Page, Pageable } from '@b3networks/api/common';
import { X_PAGINATION } from '@b3networks/shared/common';
import { ID } from '@datorama/akita';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import {
  AuthenticationMode,
  BYOPTrunkMapping,
  CarrierConnectApp,
  DetailSipAccount,
  GETBYOPTrunkMappingReq,
  ReqUpdateIpPeer,
  RoutingConfigSip,
  SipAccount,
  TypeSipAccount
} from './sip-trunk.model';
import { SipTrunkStore } from './sip-trunk.store';

@Injectable({ providedIn: 'root' })
export class SipTrunkService {
  constructor(private store: SipTrunkStore, private http: HttpClient) {}

  getAccounts(type: TypeSipAccount) {
    const params = new HttpParams().append('type', type);
    return this.http.get<SipAccount[]>('callcenter/private/v1/sipTrunk', { params: params }).pipe(
      map(entities => entities.map(i => new SipAccount(i))),
      tap(entities => this.store.upsertMany(entities, { baseClass: SipAccount }))
    );
  }

  updateAccountSipTrunk(sipUsername: string, req: Partial<SipAccount>) {
    return this.http.put<SipAccount>(`callcenter/private/v1/sipTrunk/${sipUsername}`, req).pipe(
      map(account => new SipAccount(account)),
      tap(account => {
        delete account.detail;
        this.store.upsertMany([account], { baseClass: SipAccount });
      })
    );
  }

  getDetailAccountSipTrunk(sipUsername: string, isSecondary = false) {
    return this.http.get<SipAccount>(`callcenter/private/v1/sipTrunk/${sipUsername}`).pipe(
      map(account => new SipAccount({ ...account, isSecondary })),
      tap(account => {
        this.store.upsertMany([account], { baseClass: SipAccount });
      })
    );
  }

  getRoutingConfig(sipUsername: string, keyword: string, pagable: Pageable): Observable<Page<RoutingConfigSip>> {
    let params = new HttpParams();
    if (keyword) {
      params = params.append('keyword', keyword);
    }
    if (pagable) {
      params = params.append('page', pagable.page.toString()).append('perPage', pagable.perPage.toString());
    }

    return this.http
      .get<RoutingConfigSip[]>(`callcenter/private/v1/sipTrunk/${sipUsername}/routingConfig`, {
        params: params,
        observe: 'response'
      })
      .pipe(
        map(response => {
          const page = new Page<RoutingConfigSip>();
          page.content = response.body;
          page.totalCount = +response.headers.get(X_PAGINATION.totalCount);
          return page;
        })
      );
  }
  getBYOPTrunkMapping(
    sipUsername: string,
    params?: GETBYOPTrunkMappingReq,
    pagable?: Pageable
  ): Observable<Page<BYOPTrunkMapping>> {
    let httpParams = new HttpParams();
    if (params) {
      Object.keys(params).forEach(key => {
        if (params[key]) {
          httpParams = httpParams.set(key, params[key]);
        }
      });
    }
    if (pagable) {
      httpParams = httpParams.append('page', pagable.page.toString()).append('perPage', pagable.perPage.toString());
    }
    return this.http
      .get<BYOPTrunkMapping[]>(`callcenter/private/v1/sipTrunk/${sipUsername}/byopTrunkMapping/query`, {
        params: httpParams,
        observe: 'response'
      })
      .pipe(
        map(response => {
          const page = new Page<BYOPTrunkMapping>();
          page.content = response.body;
          page.totalCount = +response.headers.get(X_PAGINATION.totalCount);
          return page;
        })
      );
  }

  createRoutingConfig(sipUsername: string, req: RoutingConfigSip) {
    return this.http.post<any>(`callcenter/private/v1/sipTrunk/${sipUsername}/routingConfig`, req);
  }

  updateRoutingConfig(sipUsername: string, req: RoutingConfigSip) {
    return this.http.put<any>(`callcenter/private/v1/sipTrunk/${sipUsername}/routingConfig/${req.number}`, req);
  }

  deleteRoutingConfig(sipUsername: string, rule: string) {
    return this.http.delete<any>(`callcenter/private/v1/sipTrunk/${sipUsername}/routingConfig/${rule}`);
  }

  getCallerIdISDN() {
    return this.http.get<string[]>('callcenter/private/v1/sipTrunk/callerIds/isdn').pipe(
      tap(entities => {
        return this.store.update({
          isdnCallerIds: entities || []
        });
      })
    );
  }

  getAvailableCallerIds() {
    return this.http.get<string[]>('callcenter/private/v1/sipTrunk/callerIds').pipe(
      tap(entities =>
        this.store.update({
          availableCallerIds: entities || []
        })
      )
    );
  }

  resetPassword(sipUsername: string, newPassword: string) {
    return this.http.put(
      `callcenter/private/v1/sipTrunk/${sipUsername}/resetPassword`,
      { newPassword: newPassword },
      { responseType: 'text' }
    );
  }

  getTLSKeyAccount(sipUsername: string) {
    return this.http.get(`appsip/accounts/${sipUsername}/tls-key`);
  }

  addIpWhiteList(sipUsername: string, list: string[]) {
    return this.http
      .put<DetailSipAccount>(`callcenter/private/v1/sipTrunk/${sipUsername}/addIpWhiteList`, {
        ips: list
      })
      .pipe(tap(detail => this.store.update(sipUsername, { detail: detail })));
  }

  removeIpWhiteList(sipUsername: string, ip: string) {
    return this.http
      .put<DetailSipAccount>(`callcenter/private/v1/sipTrunk/${sipUsername}/removeIpWhiteList`, {
        ip
      })
      .pipe(tap(detail => this.store.update(sipUsername, { detail: detail })));
  }

  updateAuthenticationMode(sipUsername: string, mode: AuthenticationMode) {
    return this.http
      .put<DetailSipAccount>(`callcenter/private/v1/sipTrunk/${sipUsername}/updateAuthenticationMode`, {
        mode: mode
      })
      .pipe(tap(detail => this.store.update(sipUsername, { detail: detail })));
  }

  updateLabel(sipUsername: string, label: string) {
    return this.http
      .put(
        `callcenter/private/v1/sipTrunk/${sipUsername}/updateLabel`,
        {
          label: label
        },
        {
          responseType: 'text'
        }
      )
      .pipe(
        tap(text => {
          this.store.update(sipUsername, entity => ({
            ...entity,
            detail: {
              ...entity.detail,
              label: text
            }
          }));
        })
      );
  }

  updateIpPeer(sipUsername: string, req: ReqUpdateIpPeer) {
    return this.http
      .put<DetailSipAccount>(`callcenter/private/v1/sipTrunk/${sipUsername}/updateIpPeer`, req)
      .pipe(tap(detail => this.store.update(sipUsername, { detail: detail })));
  }

  setActiveSip(sipUsername: string | ID) {
    this.store.setActive(sipUsername);
  }

  removeActiveSip(sipUsername: string | ID) {
    this.store.removeActive(sipUsername);
  }

  updateAccount(sipUsername: string, state: SipAccount) {
    this.store.update(sipUsername, enitty => ({
      ...enitty,
      ...state
    }));
  }

  import(sipUsername: string, fileKey: string) {
    return this.http.post(`callcenter/private/v1/sipTrunk/${sipUsername}/byopTrunkMapping/import`, { fileKey });
  }

  createOrUpdateByopRouting(sipUsername: string, req: BYOPTrunkMapping) {
    let params = new HttpParams();
    const body = { ...req };
    if (req?.oldExtKey) {
      params = params.set('oldExtKey', req.oldExtKey);
      delete body.oldExtKey;
    }

    return this.http.put<any>(`callcenter/private/v1/sipTrunk/${sipUsername}/byopTrunkMapping`, body, {
      params: params
    });
  }
  deleteByopRouting(sipUsername: string, rule: string) {
    return this.http.delete<any>(`callcenter/private/v1/sipTrunk/${sipUsername}/byopTrunkMapping/${rule}`);
  }

  getExtAvailableMapping(sipUsername: string) {
    return this.http
      .get<ExtensionBase[]>(`callcenter/private/v1/sipTrunk/${sipUsername}/byopTrunkMapping/extension/_all`)
      .pipe(
        map(exts => exts.map(x => new ExtensionBase(x))),
        tap(exts => {
          this.store.update({
            availableExt: exts || []
          });
        })
      );
  }

  getValidByopTrunkMappingNumber(sipUsername: string, byopNumber: number) {
    return this.http.get<any>(
      `callcenter/private/v1/sipTrunk/${sipUsername}/byopTrunkMapping/validateLegacyPBXExt/${byopNumber}`
    );
  }

  getCarrierConnectApp() {
    return this.http.get<CarrierConnectApp[]>(`callcenter/private/v1/carrierConnectApp`).pipe(
      tap(entities =>
        this.store.update({
          carrierConnectApp: entities || []
        })
      )
    );
  }
  updateCarrierConnectApp(req: CarrierConnectApp) {
    return this.http.put<CarrierConnectApp>(`callcenter/private/v1/carrierConnectApp/${req.id}`, req);
  }

  createCarrierConnectApp(req: CarrierConnectApp) {
    return this.http.post<CarrierConnectApp>(`callcenter/private/v1/carrierConnectApp`, req);
  }

  deleteCarrierConnectApp(req: CarrierConnectApp) {
    return this.http.delete<CarrierConnectApp>(`callcenter/private/v1/carrierConnectApp/${req.id}`);
  }
}
