import { Pipe, PipeTransform } from '@angular/core';

// This pipe to handle title case but still keep acronyms capitalize title
// Ex: edit text => Edit Text
// SMS => SMS
@Pipe({
  name: 'acronymsCapitalizeCase',
  pure: false,
  standalone: true
})
export class AcronymsCapitalizeCasePipe implements PipeTransform {
  transform(input: string): string {
    if (!input) {
      return '';
    } else {
      const textChange = input.split('_').join(' ');
      let wordsStand = textChange.split(' ');

      wordsStand = wordsStand.map(item => {
        if (item !== item.toUpperCase()) {
          return item.charAt(0).toUpperCase() + item.substr(1).toLowerCase();
        }
        return item;
      });

      return wordsStand.join(' ');
    }
  }
}
