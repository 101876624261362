import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MemberStatus } from '@b3networks/api/auth';
import { CHAT_PUBLIC_PREFIX } from '@b3networks/shared/common';
import { HashMap, ID } from '@datorama/akita';
import { Observable } from 'rxjs';
import { filter, finalize, map, take, tap } from 'rxjs/operators';
import { MeStore } from './me.store';
import { CallCenterAgent, Integration, StatusUserResponse, User, UserUI } from './user.model';
import { UserQuery } from './user.query';
import { UserState, UserStore } from './user.store';

export type ChatUserType = 'chatId' | 'identity';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  private _mapFetchingUser: HashMap<boolean> = {};
  private baseURL = `workspace/private/v1/user/`;

  constructor(
    private http: HttpClient,
    private userStore: UserStore,
    private meStore: MeStore,
    private userQuery: UserQuery
  ) {}

  getMe(): Observable<User> {
    return this.http.get<User>(`workspace/private/v1/user/me`).pipe(
      map(user => {
        const result = new User(user);
        this.meStore.update({ me: result });
        return result;
      })
    );
  }

  fetchAllUsers(): Observable<User[]> {
    return this.http.get<User[]>(`workspace/private/v1/user/all`).pipe(
      map(list => {
        const users = list.map(user => new User(user));
        this.userStore.upsertMany(users, { baseClass: User });
        return users;
      }),
      tap(() => {
        this.userStore.update({ loaded: true });
      })
    );
  }

  fetchAllUsersV2() {
    return this.http.get<User[]>(`workspace2/private/v2/user/all`).pipe(
      map(list => list.map(user => new User({ ...user, identityUuid: user.uuid, isTemporary: true }))),
      tap(users => {
        this.userStore.update({
          loadedAllUser: true
        });
        this.userStore.upsertMany(users, { baseClass: User });
      })
    );
  }

  fetchAllIntegration() {
    return this.http.get<Integration[]>('/workspace/private/v1/integrations').pipe(
      map(integrations => {
        return integrations?.map(
          i =>
            new User(<Partial<User>>{
              ...i,
              uuid: i.uuid,
              orgUuid: i.orgUuid,
              userUuid: i?.msChatUuid || i?.['userUuid'],
              displayName: i.name,
              identityUuid: i.uuid,
              photoUrl: i.photoUrl,
              memberStatus: MemberStatus.active,
              isBot: true
            })
        );
      }),
      tap(users => {
        this.userStore.upsertMany(users, { baseClass: User });
      })
    );
  }

  findByUuidAndUserType(userUuids: string[], userType: ChatUserType) {
    const params = { type: userType, uuids: userUuids.join(',') };

    return this.http.get<User[]>('/workspace/private/v1/user/members', { params: params }).pipe(
      map(result => {
        const users: User[] = result.map(
          u =>
            new User({
              ...u,
              isTemporary: false,
              userUuid: u?.['msChatUuid'] || u?.userUuid,
              identityUuid: u.identityUuid || u.uuid
            })
        );
        if (users.length < userUuids.length) {
          for (const userUuid of userUuids) {
            const index = users.findIndex(u => (userType === 'chatId' ? u.userUuid === userUuid : u.uuid === userUuid));
            if (index === -1) {
              users.push(
                new User({
                  userUuid: userType === 'chatId' ? userUuid : null,
                  identityUuid: userType === 'identity' ? userUuid : null,
                  displayName: 'Unknown Account',
                  memberStatus: MemberStatus.disabled
                })
              );
            }
          }
        }
        return users;
      }),
      tap((users: User[]) => {
        if (users?.length > 0) {
          this.userStore.upsertMany(users as User[], { baseClass: User });
        }
      })
    );
  }

  findOneByUuidAndUserTypeSequentially(userUuid: string, userType: ChatUserType) {
    if (!this._mapFetchingUser?.[userUuid]) {
      this._mapFetchingUser[userUuid] = true;
      const params = { type: userType, uuids: userUuid };
      return this.http.get<User[]>('/workspace/private/v1/user/members', { params: params }).pipe(
        map(result => {
          const users: User[] = result.map(
            u =>
              new User({
                ...u,
                isTemporary: false,
                userUuid: u?.['msChatUuid'] || u?.userUuid,
                identityUuid: u.identityUuid || u.uuid
              })
          );
          return users?.find(x => (userType === 'identity' ? x.uuid === userUuid : x.userUuid === userUuid));
        }),
        tap(
          (users: User) => {
            if (users) {
              this.userStore.upsertMany([users as User], { baseClass: User });
            }
            delete this._mapFetchingUser[userUuid];
          },
          err => delete this._mapFetchingUser[userUuid]
        )
      );
    } else {
      return userType === 'identity'
        ? this.userQuery.selectEntity(userUuid)?.pipe(
            filter(x => !!x),
            take(1)
          )
        : this.userQuery.selectUserByChatUuid(userUuid)?.pipe(
            filter(x => !!x),
            take(1)
          );
    }
  }

  initUsersStatus() {
    return this.http
      .post<{ Status: HashMap<StatusUserResponse> }>(`${CHAT_PUBLIC_PREFIX}/status/_all`, null)
      .pipe(map(x => x?.Status || <HashMap<StatusUserResponse>>{}));
  }

  updateWhatsappAgent(identityUuid: string, licence: string) {
    return this.http.put(`/workspace/private/v1/agents/${identityUuid}`, { licence: licence });
  }

  syncWhatsappAgents() {
    return this.http.post('/workspace/private/v1/agents/sync', {});
  }

  activeMember(uuid: string) {
    this.userStore.setActive(uuid);
  }

  removeActive(uuid: ID | string) {
    if (uuid) {
      this.userStore.removeActive(uuid);
    }
  }

  getAgents(): Observable<User[]> {
    this.userStore.setLoading(true);
    return this.http.get<CallCenterAgent[]>(this.baseURL + `agents`).pipe(
      map(agents => {
        return agents.map(
          a =>
            <User>{
              isAgent: true,
              identityUuid: a.identityUuid,
              displayName: a.displayName ? a.displayName : '',
              photoUrl: a.photoUrl,
              role: a.role,
              email: a.email ? a.email : '',
              uuid: a.identityUuid
            }
        );
      }),
      tap(users => {
        this.userStore.upsertMany(users, { baseClass: User });
      }),
      finalize(() => this.userStore.setLoading(false))
    );
  }

  updateAgents(agentIds: string[]) {
    return this.http.put(this.baseURL + `agents`, { agents: agentIds }).pipe(
      map(() => {
        const allUsers = this.userQuery.getAllUsers();
        return allUsers
          .filter(user => agentIds.includes(user.identityUuid))
          .map(user => <User>{ ...user, isAgent: true });
      }),
      tap(addedAgents => {
        this.userStore.upsertMany(addedAgents, { baseClass: User });
      })
    );
  }

  removeAgent(agentUuid: string) {
    return this.http.delete(this.baseURL + `agents/${agentUuid}`).pipe(
      map(() => {
        const allUsers = this.userQuery.getAllUsers();
        return allUsers.filter(user => agentUuid === user.identityUuid).map(user => <User>{ ...user, isAgent: false });
      }),
      tap(removedAgents => {
        this.userStore.upsertMany(removedAgents, { baseClass: User });
      })
    );
  }

  updateStatusUserV2(map: HashMap<StatusUserResponse>) {
    const mapCurrent = { ...this.userQuery.getValue()?.userStatus } || {};
    Object.keys(map)?.forEach(x => {
      mapCurrent[x] = map[x];
    });
    this.updateStateUser({
      userStatus: mapCurrent
    });
  }

  updateUsers2Store(users: User[]) {
    this.userStore.upsertMany(users, { baseClass: User });
  }

  updateUserViewState(uuid: string | string[], state: Partial<UserUI>) {
    this.userStore.ui.update(uuid, entity => ({
      ...entity,
      ...state
    }));
  }

  updateStateUser(state: Partial<UserState>) {
    this.userStore.update(state);
  }
}
