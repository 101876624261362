import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { GroupType } from '@b3networks/api/chat';
import { Txn, TxnChannel } from '@b3networks/api/inbox';
import { ConversationGroup, MeQuery } from '@b3networks/api/workspace';
import { UploadDialogInput, UploadDialogV2Component } from '@b3networks/chat/shared/core';
import { ConversationFooterComponent } from '@b3networks/chat/shared/livechat';
import { ConversationFooterComponent as WaConversationFooterComponent } from '@b3networks/chat/shared/whatsapp';
import { X } from '@b3networks/shared/common';
import { Observable } from 'rxjs';

@Component({
  selector: 'b3n-txn-footer',
  templateUrl: './txn-footer.component.html',
  styleUrls: ['./txn-footer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [CommonModule, ConversationFooterComponent, WaConversationFooterComponent]
})
export class TxnFooterComponent {
  @Input() txn: Txn;
  @Input() convo: ConversationGroup;

  txn$: Observable<Txn>;

  readonly TxnChannel = TxnChannel;

  constructor(private dialog: MatDialog, private meQuery: MeQuery) {}

  uploadFile(models: File[], convo: ConversationGroup, index: number) {
    if (!convo || (convo && convo.type === GroupType.SMS)) {
      return;
    }

    const dialog = this.dialog.open(UploadDialogV2Component, {
      width: '500px',
      disableClose: true,
      data: <UploadDialogInput>{
        file: models[index],
        ticket: convo,
        index: index + 1,
        max: models.length,
        orgUuid: X.orgUuid,
        meChatUser: this.meQuery.getMe()?.userUuid,
        txnUuid: this.txn?.txnUuid,
        txn: this.txn
      }
    });

    dialog.afterClosed().subscribe(
      _ => {
        // next
        index = index + 1;
        if (index < models.length) {
          this.uploadFile(models, convo, index);
        }
      },
      err => {
        // next
        index = index + 1;
        if (index < models.length) {
          this.uploadFile(models, convo, index);
        }
      }
    );
  }
}
