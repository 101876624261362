import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Pageable } from '@b3networks/api/common';
import { Contact } from '@b3networks/api/contact';
import { User } from '@b3networks/api/workspace';
import { ID, resetStores } from '@datorama/akita';
import { unionBy } from 'lodash';
import { Observable } from 'rxjs';
import { finalize, map, tap } from 'rxjs/operators';
import { CreatedTxn } from '../created-txn/created-txn.model';
import { CreatedTxnsService } from '../created-txn/created-txn.service';
import { ResponseUpdateFile } from '../livechat/livechat.model';
import { RelatedTxn } from '../related-txn/related-txn.model';
import { RelatedTxnsService } from '../related-txn/related-txn.service';
import { CaseActivity } from '../support-center/activities/activities.model';
import { Watchers } from '../watchers/watchers.model';
import { WatchersService } from '../watchers/watchers.service';
import { TxnUI } from './txn-ui.model';
import {
  ActionTxn,
  AssignLeftReq,
  AssignedMode,
  CaseInfo,
  CreateCaseCommentReq,
  ReqSendMsgInbox,
  RequestActionTxn,
  RequestFilterCustomerTxnsV2,
  RequestFilterTxnsV2,
  RequestFilterTxnsV4,
  RequestUpdateTxns,
  RequestUploadFileTxn,
  RespActivePendingTxn,
  RespDetailTxn,
  Txn,
  TxnChannel,
  TxnGroupBy,
  TxnStatus,
  ViewMode
} from './txn.model';
import { TxnQuery } from './txn.query';
import { TxnStore } from './txn.store';

export const TXNS_SUPPORT = [TxnChannel.livechat, TxnChannel.whatsapp, TxnChannel.call, TxnChannel.supportCenter];
export const PENDING_TXNS = [TxnStatus.waiting, TxnStatus.assigning];

@Injectable({
  providedIn: 'root'
})
export class TxnService {
  listNotifyAssign2Me: string[] = []; // txnUuid

  constructor(
    private http: HttpClient,
    private store: TxnStore,
    private query: TxnQuery,
    private watchersService: WatchersService,
    private relatedTxnsService: RelatedTxnsService,
    private createdTxnservice: CreatedTxnsService
  ) {}

  assign(req: AssignLeftReq) {
    return this.http.post<void>('callcenter/private/v1/chat/txn/assign', req);
  }

  archive(convoId: string) {
    return this.http.post<void>(`callcenter/private/v1/chat/txn/end`, { txnUuid: convoId });
  }

  left(req: AssignLeftReq) {
    return this.http.post<void>(`callcenter/private/v1/chat/txn/left`, req);
  }

  join(convoId: string) {
    return this.http.post<void>(`workspace/private/v1/chat/${convoId}/join`, null);
  }

  verifiedTxnsV2(txnUuid: string) {
    return this.http
      .put<Contact>(`inbox/private/v2/txn/${txnUuid}/markVerified`, {})
      .pipe(map(contact => new Contact(contact)));
  }

  endTxnsV2(txnUuids: string[]) {
    return this.http.post(`inbox/private/v2/livechat/_end`, { txnUuids: txnUuids });
  }

  endTxnsV3(txnUuid: string) {
    return this.http.put(`inbox/private/v2/txn/${txnUuid}/_end`, {});
  }

  reopenTxnsV3(txnUuid: string) {
    return this.http.put(`inbox/private/v2/txn/${txnUuid}/_reopen`, {});
  }

  joinTxnV2(txnUuid: string, agentUuid: string) {
    return this.http.post(`inbox/private/v2/txn/${txnUuid}/_assign`, { agentUuid: agentUuid });
  }

  leftTxnV2(txnUuid: string, agentUuid: string) {
    return this.http.post(`inbox/private/v2/txn/${txnUuid}/_unassign`, { agentUuid: agentUuid });
  }

  moveInbox(txnUuid: string, assignees: string[], destinationInbox: string) {
    return this.http.put(`inbox/private/v2/txn/${txnUuid}/_move`, {
      assignees,
      destinationInbox
    });
  }

  updateTxnV2(txnUuid: string, req: RequestUpdateTxns) {
    return this.http.put<Txn>(`inbox/private/v2/txn/${txnUuid}`, req).pipe(
      map(txn => this.convertTxn(txn, { isTemporary: true })),
      map(txn => new Txn(txn)),
      tap(txn => this.updateTxns2Store([txn]))
    );
  }

  acceptOrRejectTxn(txnUuid: string, action: ActionTxn, notificationId: number) {
    return this.http.post<Txn>(`inbox/private/v2/txn/${txnUuid}/${notificationId}/${action}`, {});
  }

  requestAssignTxn(txnUuid: string, req: RequestActionTxn) {
    return this.http.post<Txn>(`inbox/private/v2/txn/${txnUuid}/_requestAssign`, req);
  }

  getTxnByFilterV2(
    req: RequestFilterTxnsV2,
    pageable: Pageable,
    meIdentity?: string
  ): Observable<RespActivePendingTxn> {
    let params = new HttpParams();
    Object.keys(pageable).forEach(key => {
      if (pageable[key]) {
        params = params.append(key, String(pageable[key]));
      }
    });

    return this.http.post<Txn[]>('inbox/private/v4/txn/_query', req, { params: params }).pipe(
      map(res => {
        let txns: Txn[] = [];
        let contacts: Contact[] =
          res
            ?.filter(x => !!x?.['customer'])
            ?.map(
              txn =>
                new Contact({
                  uuid: txn?.['customer']?.['uuid'],
                  displayName: txn?.['customer']?.['displayName'],
                  chatCustomerId: txn?.['customer']?.['chatUserId']
                })
            ) || [];
        contacts = unionBy(contacts, 'uuid');

        if (req.groupBy === TxnGroupBy.customer) {
          res.forEach(item => {
            Object.keys(item?.['txnMap'] || {})?.forEach(key => {
              const txn = item?.['txnMap'][key];
              const teamUnread = !txn?.teamConvoUnread || txn?.teamConvoUnread < 0 ? 0 : txn?.teamConvoUnread;
              txn.txnUuid = key;
              const data = this.convertTxn(txn, { isTemporary: true });
              data.unreadCount = (txn?.publicConvoUnread || 0) + teamUnread;
              if (
                req.assignedMode === AssignedMode.me &&
                (!data.lastAssignedAgents || data.lastAssignedAgents?.length === 0) &&
                !data.lastAssignedAgents?.includes(meIdentity)
              ) {
                data.lastAssignedAgents = [meIdentity];
              }

              if (item?.['customer']) {
                if (item['customer']?.uuid) data.customerUuid = item['customer']?.uuid;
                if (item['customer']?.displayName) data.customerName = item['customer']?.displayName;
                if (item['customer']?.chatUserId) data.customerChatUserId = item['customer']?.chatUserId;
              }
              txns.push(new Txn(data));
            });
          });
        } else {
          // groupby txn
          txns = res.map(item => {
            const data = this.convertTxn(item, { isTemporary: true });
            if (
              req.assignedMode === AssignedMode.me &&
              (!data.lastAssignedAgents || data.lastAssignedAgents?.length === 0) &&
              !data.lastAssignedAgents?.includes(meIdentity)
            ) {
              data.lastAssignedAgents = [meIdentity];
            }

            return new Txn(data);
          });
        }
        if (req.viewMode === ViewMode.watcher) {
          const watchers: Watchers[] = txns?.map(txn => {
            return {
              txnUuid: txn.txnUuid,
              watchers: [meIdentity]
            } as Watchers;
          });

          this.watchersService.updateWatchers2Store(watchers);
        }

        if (req.viewMode === ViewMode.mention) {
          const relatedTxns: RelatedTxn[] = txns?.map(txn => {
            return {
              txnUuid: txn.txnUuid,
              mentions: [meIdentity]
            } as RelatedTxn;
          });

          this.relatedTxnsService.updateRelatedTxns2Store(relatedTxns);
        }

        if (req.viewMode === ViewMode.createdByMe) {
          const createdByMe: CreatedTxn[] = txns?.map(txn => {
            return {
              txnUuid: txn.txnUuid,
              createdBy: meIdentity
            } as CreatedTxn;
          });

          this.createdTxnservice.updateCreatedTxns2Store(createdByMe);
        }

        return <RespActivePendingTxn>{ txns, contacts };
      }),
      tap(txnList => this.updateTxns2Store(txnList.txns))
    );
  }

  getTxnByFilterV4(
    myOrgUuid: string,
    req: RequestFilterTxnsV4,
    pageable: Pageable,
    meIdentity?: string
  ): Observable<RespActivePendingTxn> {
    let params = new HttpParams();
    Object.keys(pageable).forEach(key => {
      if (pageable[key]) {
        params = params.append(key, String(pageable[key]));
      }
    });

    return this.http.post<Txn[]>('inbox/private/v5/txn/_query', req, { params: params }).pipe(
      map(res => {
        let txns: Txn[] = [];
        let contacts: Contact[] =
          res
            ?.filter(x => !!x?.['customer'])
            ?.map(
              txn =>
                new Contact({
                  uuid: txn?.['customer']?.['uuid'],
                  displayName: txn?.['customer']?.['displayName'],
                  chatCustomerId: txn?.['customer']?.['chatUserId']
                })
            ) || [];
        contacts = unionBy(contacts, 'uuid');

        txns = res.map(item => {
          const data = this.convertTxn(item, { isTemporary: true });
          if (
            req.viewMode === ViewMode.assigned &&
            (!data.lastAssignedAgents || data.lastAssignedAgents?.length === 0) &&
            !data.lastAssignedAgents?.includes(meIdentity)
          ) {
            data.lastAssignedAgents = [meIdentity];
          }

          return new Txn(data).withOrgUuid(myOrgUuid);
        });

        if (req.viewMode === ViewMode.watcher) {
          const watchers: Watchers[] = txns?.map(txn => {
            return {
              txnUuid: txn.txnUuid,
              watchers: [meIdentity]
            } as Watchers;
          });

          this.watchersService.updateWatchers2Store(watchers);
        }

        if (req.viewMode === ViewMode.mention) {
          const relatedTxns: RelatedTxn[] = txns?.map(txn => {
            return {
              txnUuid: txn.txnUuid,
              mentions: [meIdentity]
            } as RelatedTxn;
          });

          this.relatedTxnsService.updateRelatedTxns2Store(relatedTxns);
        }

        if (req.viewMode === ViewMode.createdByMe) {
          const createdByMe: CreatedTxn[] = txns?.map(txn => {
            return {
              txnUuid: txn.txnUuid,
              createdBy: meIdentity
            } as CreatedTxn;
          });

          this.createdTxnservice.updateCreatedTxns2Store(createdByMe);
        }

        return <RespActivePendingTxn>{ txns, contacts };
      }),
      tap(txnList => this.updateTxns2Store(txnList.txns))
    );
  }

  getDetailTxnV2(txnUuid: string): Observable<RespDetailTxn> {
    return this.http.get<Txn>(`inbox/private/v3/txn/${txnUuid}`).pipe(
      map(item => {
        const data = this.convertTxn(item, { isTemporary: true });
        return <RespDetailTxn>{
          txn: new Txn(data),
          contact: item?.['customerInfo'] ? new Contact(item['customerInfo']) : null
        };
      }),
      tap(data => {
        this.addTxns2Store(data.txn);
        this.updateTxnViewState(data.txn.txnUuid, {
          loadedDetail: true
        });
      })
    );
  }

  getTxnByCustomerV2(
    req: RequestFilterCustomerTxnsV2,
    pagable: Pageable,
    config: { flagFetching: boolean } = { flagFetching: true }
  ): Observable<RespActivePendingTxn> {
    let params = new HttpParams();
    Object.keys(pagable).forEach(key => {
      if (pagable[key]) {
        params = params.append(key, String(pagable[key]));
      }
    });

    if (config?.flagFetching) {
      this.store.update({
        fetchingTxn: true
      });
    }

    return this.http.post<Txn[]>('inbox/private/v3/txn/_getByCustomer', req, { params: params }).pipe(
      map(res => {
        let contacts: Contact[] =
          res
            ?.filter(x => !!x?.['customer'])
            ?.map(
              txn =>
                new Contact({
                  uuid: txn?.['customer']?.['uuid'],
                  displayName: txn?.['customer']?.['displayName'],
                  chatCustomerId: txn?.['customer']?.['chatUserId']
                })
            ) || [];
        contacts = unionBy(contacts, 'uuid');

        let txns: Txn[] = [];
        txns = res.map(item => {
          const store = this.query.getEntity(item.txnUuid);
          const data = this.convertTxn(item, { isTemporary: store?.isTemporary });
          return new Txn(data);
        });
        return <RespActivePendingTxn>{ txns, contacts };
      }),
      tap(txnList => this.updateTxns2Store(txnList.txns)),
      finalize(() => {
        if (config?.flagFetching) {
          this.store.update({
            fetchingTxn: false
          });
        }
      })
    );
  }

  queryTxnByFilter(
    req: RequestFilterCustomerTxnsV2,
    pagable: Pageable,
    config: { flagFetching: boolean } = { flagFetching: true }
  ): Observable<RespActivePendingTxn> {
    let params = new HttpParams();
    Object.keys(pagable).forEach(key => {
      if (pagable[key]) {
        params = params.append(key, String(pagable[key]));
      }
    });

    if (config?.flagFetching) {
      this.store.update({
        fetchingTxn: true
      });
    }

    return this.http.post<Txn[]>('inbox/private/v5/txn/_query', req, { params: params }).pipe(
      map(res => {
        let contacts: Contact[] =
          res
            ?.filter(x => !!x?.['customer'])
            ?.map(
              txn =>
                new Contact({
                  uuid: txn?.['customer']?.['uuid'],
                  displayName: txn?.['customer']?.['displayName'],
                  chatCustomerId: txn?.['customer']?.['chatUserId']
                })
            ) || [];
        contacts = unionBy(contacts, 'uuid');

        let txns: Txn[] = [];
        txns = res.map(item => {
          const store = this.query.getEntity(item.txnUuid);
          const data = this.convertTxn(item, { isTemporary: store?.isTemporary });
          return new Txn(data);
        });
        return <RespActivePendingTxn>{ txns, contacts };
      }),
      tap(txnList => this.updateTxns2Store(txnList.txns)),
      finalize(() => {
        if (config?.flagFetching) {
          this.store.update({
            fetchingTxn: false
          });
        }
      })
    );
  }

  getAllTicketByViewMode(
    req: RequestFilterCustomerTxnsV2,
    pagable: Pageable,
    config: { flagFetching: boolean } = { flagFetching: true }
  ): Observable<RespActivePendingTxn> {
    let params = new HttpParams();
    Object.keys(pagable).forEach(key => {
      if (pagable[key]) {
        params = params.append(key, String(pagable[key]));
      }
    });

    if (config?.flagFetching) {
      this.store.update({
        fetchingTxn: true
      });
    }

    return this.http.post<Txn[]>('inbox/private/v3/txn/_query/ticket', req, { params: params }).pipe(
      map(res => {
        let contacts: Contact[] =
          res
            ?.filter(x => !!x?.['customer'])
            ?.map(
              txn =>
                new Contact({
                  uuid: txn?.['customer']?.['uuid'],
                  displayName: txn?.['customer']?.['displayName'],
                  chatCustomerId: txn?.['customer']?.['chatUserId']
                })
            ) || [];
        contacts = unionBy(contacts, 'uuid');

        let txns: Txn[] = [];
        txns = res.map(item => {
          const store = this.query.getEntity(item.txnUuid);
          const data = this.convertTxn(item, { isTemporary: store?.isTemporary });

          return new Txn(data);
        });

        return <RespActivePendingTxn>{ txns, contacts };
      }),
      tap(txnList => this.updateTxns2Store(txnList.txns)),
      finalize(() => {
        if (config?.flagFetching) {
          this.store.update({
            fetchingTxn: false
          });
        }
      })
    );
  }

  getTxnWatchedByCustomerV2(
    req: RequestFilterCustomerTxnsV2,
    pagable: Pageable,
    config: { flagFetching: boolean } = { flagFetching: true }
  ): Observable<RespActivePendingTxn> {
    let params = new HttpParams();
    Object.keys(pagable).forEach(key => {
      if (pagable[key]) {
        params = params.append(key, String(pagable[key]));
      }
    });

    if (config?.flagFetching) {
      this.store.update({
        fetchingTxn: true
      });
    }

    return this.http.post<Txn[]>('inbox/private/v1/txn/_getWatchedByCustomer', req, { params: params }).pipe(
      map(res => {
        let contacts: Contact[] =
          res
            ?.filter(x => !!x?.['customer'])
            ?.map(
              txn =>
                new Contact({
                  uuid: txn?.['customer']?.['uuid'],
                  displayName: txn?.['customer']?.['displayName'],
                  chatCustomerId: txn?.['customer']?.['chatUserId']
                })
            ) || [];
        contacts = unionBy(contacts, 'uuid');

        let txns: Txn[] = [];
        txns = res.map(item => {
          const store = this.query.getEntity(item.txnUuid);
          const data = this.convertTxn(item, { isTemporary: store?.isTemporary });

          return new Txn(data);
        });
        return <RespActivePendingTxn>{ txns, contacts };
      }),
      tap(txnList => this.updateTxns2Store(txnList.txns)),
      finalize(() => {
        if (config?.flagFetching) {
          this.store.update({
            fetchingTxn: false
          });
        }
      })
    );
  }

  getTxnRelatedByCustomerV2(
    req: RequestFilterCustomerTxnsV2,
    pagable: Pageable,
    config: { flagFetching: boolean } = { flagFetching: true }
  ): Observable<RespActivePendingTxn> {
    let params = new HttpParams();
    Object.keys(pagable).forEach(key => {
      if (pagable[key]) {
        params = params.append(key, String(pagable[key]));
      }
    });

    if (config?.flagFetching) {
      this.store.update({
        fetchingTxn: true
      });
    }

    return this.http.post<Txn[]>('inbox/private/v1/txn/_getMentionedByCustomer', req, { params: params }).pipe(
      map(res => {
        let contacts: Contact[] =
          res
            ?.filter(x => !!x?.['customer'])
            ?.map(
              txn =>
                new Contact({
                  uuid: txn?.['customer']?.['uuid'],
                  displayName: txn?.['customer']?.['displayName'],
                  chatCustomerId: txn?.['customer']?.['chatUserId']
                })
            ) || [];
        contacts = unionBy(contacts, 'uuid');

        let txns: Txn[] = [];
        txns = res.map(item => {
          const store = this.query.getEntity(item.txnUuid);
          const data = this.convertTxn(item, { isTemporary: store?.isTemporary });

          return new Txn(data);
        });
        return <RespActivePendingTxn>{ txns, contacts };
      }),
      tap(txnList => this.updateTxns2Store(txnList.txns)),
      finalize(() => {
        if (config?.flagFetching) {
          this.store.update({
            fetchingTxn: false
          });
        }
      })
    );
  }

  getTxnCreatedByMeForCustomerV2(
    req: RequestFilterCustomerTxnsV2,
    pagable: Pageable,
    config: { flagFetching: boolean } = { flagFetching: true }
  ): Observable<RespActivePendingTxn> {
    let params = new HttpParams();
    Object.keys(pagable).forEach(key => {
      if (pagable[key]) {
        params = params.append(key, String(pagable[key]));
      }
    });

    if (config?.flagFetching) {
      this.store.update({
        fetchingTxn: true
      });
    }

    return this.http.post<Txn[]>('inbox/private/v1/txn/_getCreatedByMeForCustomer', req, { params: params }).pipe(
      map(res => {
        let contacts: Contact[] =
          res
            ?.filter(x => !!x?.['customer'])
            ?.map(
              txn =>
                new Contact({
                  uuid: txn?.['customer']?.['uuid'],
                  displayName: txn?.['customer']?.['displayName'],
                  chatCustomerId: txn?.['customer']?.['chatUserId']
                })
            ) || [];
        contacts = unionBy(contacts, 'uuid');

        let txns: Txn[] = [];
        txns = res.map(item => {
          const store = this.query.getEntity(item.txnUuid);
          const data = this.convertTxn(item, { isTemporary: store?.isTemporary });

          return new Txn(data);
        });
        return <RespActivePendingTxn>{ txns, contacts };
      }),
      tap(txnList => this.updateTxns2Store(txnList.txns)),
      finalize(() => {
        if (config?.flagFetching) {
          this.store.update({
            fetchingTxn: false
          });
        }
      })
    );
  }

  getTxnByTeamConvo(internalConvo: string) {
    return this.http.get<Txn>(`inbox/private/v3/txn/internalConvo/${internalConvo}`).pipe(
      map(res => {
        const contact: Contact = res?.['customer']
          ? new Contact({
              uuid: res?.['customer']?.['uuid'],
              displayName: res?.['customer']?.['displayName'],
              chatCustomerId: res?.['customer']?.['chatUserId']
            })
          : null;

        const store = this.query.getEntity(res?.txnUuid);
        const data = this.convertTxn(res, { isTemporary: store?.isTemporary });
        return <RespActivePendingTxn>{ txns: [new Txn(data)], contacts: [contact] };
      }),
      tap(txnList => this.updateTxns2Store(txnList.txns))
    );
  }

  updateTxn2Store(txnUuid: string | ID, data: Partial<Txn>) {
    const txn = this.query.getEntity(txnUuid);
    if (TXNS_SUPPORT.includes(txn?.channel)) {
      this.store.upsert(txnUuid, data, { baseClass: Txn });
    }
  }

  updateTxns2Store(data: Partial<Txn[]>) {
    const list = data.filter(txn => TXNS_SUPPORT.includes(txn.channel));
    if (list.length > 0) {
      this.store.upsertMany(list, { baseClass: Txn });
    }
  }

  addTxns2Store(txn: Txn) {
    if (TXNS_SUPPORT.includes(txn?.channel)) {
      this.store.upsert(txn.txnUuid, txn, { baseClass: Txn });
      return true;
    }
    return false;
  }

  updateTxnViewState(txnUuid: string | string[], state: Partial<TxnUI>) {
    this.store.ui.update(txnUuid, entity => ({
      ...entity,
      ...state
    }));
  }

  markSeen(txnUuid: string) {
    this.store.update(txnUuid, { unreadCount: 0 });
  }

  countUnread(txnUuid: string) {
    const txn = this.query.getEntity(txnUuid);
    if (txn) {
      this.updateTxn2Store(txnUuid, {
        unreadCount: (txn.unreadCount || 0) + 1
      });
    }
  }

  resetStoreTxn() {
    resetStores();
    this.store.ui.reset();
  }

  removeWithoutActive() {
    const activeId = this.query.getActiveId();
    this.store.remove(entity => entity.txnUuid !== activeId);
  }

  setActive(channelId: string | ID) {
    this.store.setActive(channelId);
  }

  removeActive(channelId: string | ID) {
    this.store.removeActive(channelId);
  }

  createComment(txnUuid: string, comment: CreateCaseCommentReq) {
    return this.http.post<CaseActivity>(`inbox/private/v1/txn/${txnUuid}/comments`, comment).pipe();
  }

  updateComment(txnUuid: string, commentId: number, comment: CreateCaseCommentReq) {
    return this.http.put<CaseActivity>(`inbox/private/v1/txn/${txnUuid}/comments/${commentId}`, comment);
  }

  deleteComment(txnUuid: string, commentId: number) {
    return this.http.delete<void>(`inbox/private/v1/txn/${txnUuid}/comments/${commentId}`);
  }

  uploadFile(txnUuid: string, req: RequestUploadFileTxn) {
    return this.http.post<ResponseUpdateFile>(`inbox/private/v2/txn/${txnUuid}/files`, req);
  }

  uploadFileAsync(txnUuid: string, req: RequestUploadFileTxn) {
    return this.http.post<ResponseUpdateFile>(`inbox/private/v2/txn/${txnUuid}/files/async`, req);
  }

  statusUploadAsync(txnUuid: string, asyncId: number) {
    return this.http.get<any>(`inbox/private/v2/txn/${txnUuid}/files/async/${asyncId}`);
  }

  getTraceByTxn(txnUuid: string, txnTrace: string) {
    return this.http.get<any>(`inbox/private/v2/txn/${txnUuid}/trace?txnTrace=${txnTrace}`);
  }

  moveInboxUpdateState(txnUuid: string, fromInbox: string, toInbox: string) {
    const txn = this.query.getEntity(txnUuid);
    if (txn) {
      this.store.update(txnUuid, {
        inboxUuid: toInbox
      });
    }
  }

  migrateContactWhenMerged(toContact: string, mergedContacts: string[]) {
    const txns = this.query.getAll({
      filterBy: entity => mergedContacts?.includes(entity.customerUuid)
    });

    this.store.update(
      txns?.map(x => x.txnUuid),
      {
        customerUuid: toContact
      }
    );
  }

  sendMsgInbox(txnUuid: string, req: ReqSendMsgInbox) {
    return this.http.post(`inbox/private/v2/txn/${txnUuid}/messages`, req);
  }

  private convertTxn(item: Partial<Txn>, state: { isTemporary: boolean }) {
    const txn = <Partial<Txn>>{};

    if (item.txnUuid) txn.txnUuid = item.txnUuid;
    if (item.inboxUuid) txn.inboxUuid = item.inboxUuid;

    if (item.teamConvoId) txn.teamConvoId = item.teamConvoId;
    if (item.publicConvoId) txn.publicConvoId = item.publicConvoId;

    if (item?.['assignees']) txn.lastAssignedAgents = item['assignees'];
    if (item.metadata) txn.metadata = item.metadata;
    if (item.createdAt) txn.createdAt = item.createdAt;
    if (item.channel) txn.channel = item.channel;
    if (item.title) txn.title = item.title;
    if (item.status) txn.status = item.status;
    if (item.whatsapp) txn.whatsapp = item.whatsapp;
    if (item.typeId) txn.typeId = item.typeId;
    if (item.productIds) txn.productIds = item.productIds;
    if (item.severityId) txn.severityId = item.severityId;
    if (item.transcriptKey) txn.transcriptKey = item.transcriptKey;
    if (item.assigningTo) txn.assigningTo = item.assigningTo;
    if (item.affectedOrgName) txn.affectedOrgName = item.affectedOrgName;
    if (item.affectedOrgUuid) txn.affectedOrgUuid = item.affectedOrgUuid;
    if (item.sid) txn.sid = item.sid;

    //support ticket
    if (item.caseInfo) {
      txn.caseInfo = this.convertCaseInfo(item.caseInfo);
    } else {
      const caseInfoBase = <CaseInfo>{ ...this.query.getEntity(txn.txnUuid)?.caseInfo };
      if (item['ownerOrgUuid']) caseInfoBase.ownerOrgUuid = item['ownerOrgUuid'];
      if (item['ownerOrgName']) caseInfoBase.ownerOrgName = item['ownerOrgName'];
      if (item['createdByIdentity']) caseInfoBase.createdByIdentity = item['createdByIdentity'];
      if (item['createdByIdentityName']) caseInfoBase.createdByIdentityName = item['createdByIdentityName'];
      if (item['createdByOrgName']) caseInfoBase.createdByOrgName = item['createdByOrgName'];
      if (item['createdByOrg']) caseInfoBase.createdByOrg = item['createdByOrg'];
      txn.caseInfo = this.convertCaseInfo(caseInfoBase);
    }
    if (item?.['ownerAssignees']?.length > 0) {
      txn.lastOwnerAssignedAgents = new User(item['ownerAssignees'][0]);
    } else {
      const infoOwnerAssignedBase = new User(<User>{ ...this.query.getEntity(txn.txnUuid)?.lastOwnerAssignedAgents });
      txn.lastOwnerAssignedAgents = infoOwnerAssignedBase;
    }

    if (item.endedAt) txn.endedAt = item.endedAt;

    // customer
    if (item?.['customer']) {
      if (item['customer']?.uuid) txn.customerUuid = item['customer']?.uuid;
      if (item['customer']?.displayName) txn.customerName = item['customer']?.displayName;
      if (item['customer']?.chatUserId) txn.customerChatUserId = item['customer']?.chatUserId;
    }

    // customer Info
    if (item?.['customerInfo']) {
      if (item['customerInfo']?.uuid) txn.customerUuid = item['customerInfo']?.uuid;
      if (item['customerInfo']?.displayName) txn.customerName = item['customerInfo']?.displayName;
      if (item['customerInfo']?.chatCustomerId) txn.customerChatUserId = item['customerInfo']?.chatCustomerId;
    }

    txn.isTemporary = state?.isTemporary;
    return txn;
  }

  private convertCaseInfo(item: CaseInfo) {
    const caseInfo = <CaseInfo>{};
    if (item.id) caseInfo.id = item.id;
    if (item.sid) caseInfo.sid = item.sid;
    if (item.txnUuid) caseInfo.txnUuid = item.txnUuid;
    if (item.description) caseInfo.description = item.description;

    if (item.ownerOrgUuid) caseInfo.ownerOrgUuid = item.ownerOrgUuid;
    if (item.ownerOrgName) caseInfo.ownerOrgName = item.ownerOrgName;

    if (item.srcOrgUuid) caseInfo.srcOrgUuid = item.srcOrgUuid;
    if (item.srcDomain) caseInfo.srcDomain = item.srcDomain;

    if (item.createdByIdentity) caseInfo.createdByIdentity = item.createdByIdentity;
    if (item.createdByIdentityName) caseInfo.createdByIdentityName = item.createdByIdentityName;

    if (item.createdByOrg) caseInfo.createdByOrg = item.createdByOrg;
    if (item.createdByOrgName) caseInfo.createdByOrgName = item.createdByOrgName;

    if (item.accessControlId) caseInfo.accessControlId = item.accessControlId;
    if (item.dueAt) caseInfo.dueAt = item.dueAt;

    if (item.watchers) caseInfo.watchers = item.watchers;
    if (item.activities) caseInfo.activities = item.activities;
    if (item.relatedCases) caseInfo.relatedCases = item.relatedCases;

    return caseInfo;
  }
}
